import React, {useEffect, useState} from 'react';
import {Drawer, List, ListItem, ListItemText, Collapse, ListItemButton} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {useRecoilValue} from "recoil";
import {cateList} from "../../../store/cateStore";
import {memberState} from "../../../store/memberStore";
import {useLocation} from "react-router-dom";
import {colorState} from "../../../store/colorStore";

const Menu = ({openSidebar, openDropdown, toggleSidebar, handleClick, pathHandler}) => {
    const color = useRecoilValue(colorState);
    const [openShop, setOpenShop] = useState(true);
    const [openManage, setOpenManage] = useState(false);
    const [openPaylist, setOpenPaylist] = useState(false);
    const [join, setJoin] = useState(false);
    const shopMenuList = useRecoilValue(cateList);
    const memberInfo = useRecoilValue(memberState);
    const location = useLocation();

    useEffect(() => {
    }, [location]);

    const openShopHandler = () => {
        setOpenShop(!openShop);
        setOpenManage(false);
        setJoin(false);
        setOpenPaylist(false)
    };

    const openPayLIstHandler = () => {
        setOpenPaylist(!openPaylist)
        setOpenShop(false);
        setOpenManage(false);
        setJoin(false);
    };

    const openManageHandler = () => {
        setOpenManage(!openManage);
        setOpenShop(false);
        setJoin(false);
        setOpenPaylist(false)
    };

    const openJoin = () => {
        setJoin(!join);
        setOpenShop(false);
        setOpenManage(false);
        setOpenPaylist(false)
    };

    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={openSidebar}
            sx={{
                '& .MuiDrawer-paper': {
                    width: '180px',
                    bgcolor: '#ffffff',
                    color: 'black',
                    mt: '74px',
                    borderRight: '1px solid' + color,
                    borderTop: '1px solid' + color,
                    boxShadow: '4px 0 10px -2px rgba(0, 0, 0, 0.2)',
                    borderRadius: '0 10px 10px 0',
                    zIndex: 100,
                },
            }}
        >
            <List>
                <ListItem onClick={() => pathHandler('/')} button
                          sx={{
                              pl: 4,
                              backgroundColor: location.pathname === '/' ? color : 'white',
                              color: location.pathname === '/' ? 'white' : '',
                              '&:hover': {
                                  backgroundColor: color,
                                  color: 'white'
                              },
                          }}
                >
                    <ListItemText sx={{borderBottom: '1px solid #004E8A'}} className={'home'} primary="HOME"/>
                </ListItem>
                {/*<ListItem onClick={() => pathHandler('/bbs')} button*/}
                {/*          sx={{*/}
                {/*              pl: 4,*/}
                {/*              backgroundColor: location.pathname === '/bbs' ? '#004E8A' : 'white',*/}
                {/*              color: location.pathname === '/bbs' ? 'white' : '',*/}
                {/*              '&:hover': {*/}
                {/*                  backgroundColor: '#004E8A',*/}
                {/*                  color: 'white'*/}
                {/*              },*/}
                {/*          }}*/}
                {/*>*/}
                {/*    <ListItemText sx={{borderBottom: '1px solid #004E8A'}} className={'pointer'} primary="공지사항"/>*/}
                {/*</ListItem>*/}
                <ListItem onClick={() => pathHandler('/statistics')} button
                          sx={{
                              pl: 4,
                              backgroundColor: location.pathname === '/statistics' ? color : 'white',
                              color: location.pathname === '/statistics' ? 'white' : '',
                              '&:hover': {
                                  backgroundColor: color,
                                  color: 'white'
                              },
                          }}>
                    <ListItemText sx={{borderBottom: '1px solid' + color}} className={'statistics'} primary="월 정산"/>
                </ListItem>
                <ListItem onClick={() => pathHandler('/paylist')} button
                          sx={{
                              pl: 4,
                              backgroundColor: location.pathname === '/paylist' ? color : 'white',
                              color: location.pathname === '/paylist' ? 'white' : '',
                              '&:hover': {
                                  backgroundColor: color,
                                  color: 'white'
                              },
                          }}
                >
                    <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'} primary="결제 목록"/>
                </ListItem>

                {memberInfo.memRole === '본사' &&
                    <>
                        <ListItemButton
                            sx={{
                                pl: 4, marginRight: 0,
                                color: openPaylist ? 'white' : '',
                                backgroundColor: openPaylist ? color : '',
                                '&:hover': {
                                    backgroundColor: color,
                                    color: 'white'
                                },
                            }}
                            onClick={openPayLIstHandler}
                        >
                            <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                          primary="정산 관리"/>
                            {openPaylist ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={openPaylist} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{
                                        pl: 6, pr: 3,
                                        backgroundColor: decodeURIComponent(location.pathname) === `/duple` ? 'white' : color,
                                        color: decodeURIComponent(location.pathname) === `/duple` ? '' : 'white',
                                        '&:hover': {
                                            backgroundColor: '#white',
                                            color: 'black'
                                        },
                                    }}
                                    onClick={() => (pathHandler(`/duple`))}>
                                    <ListItemText
                                        sx={{
                                            borderBottom: '1px solid' + color
                                        }} primary={'중복 및 초과'}/>
                                </ListItemButton>
                                <ListItemButton
                                    sx={{
                                        pl: 6, pr: 3,
                                        backgroundColor: decodeURIComponent(location.pathname) === `/holdList` ? 'white' : color,
                                        color: decodeURIComponent(location.pathname) === `/holdList` ? '' : 'white',
                                        '&:hover': {
                                            backgroundColor: '#white',
                                            color: 'black'
                                        },
                                    }}
                                    onClick={() => (pathHandler(`/holdList`))}>
                                    <ListItemText
                                        sx={{
                                            borderBottom: '1px solid' + color
                                        }} primary={'보류 목록'}/>
                                </ListItemButton>
                                <ListItemButton
                                    sx={{
                                        pl: 6, pr: 3,
                                        backgroundColor: decodeURIComponent(location.pathname) === `/cclist` ? 'white' : color,
                                        color: decodeURIComponent(location.pathname) === `/cclist` ? '' : 'white',
                                        '&:hover': {
                                            backgroundColor: '#white',
                                            color: 'black'
                                        },
                                    }}
                                    onClick={() => (pathHandler(`/cclist`))}>
                                    <ListItemText
                                        sx={{
                                            borderBottom: '1px solid' + color
                                        }} primary={'정산 목록'}/>
                                </ListItemButton>
                                <ListItemButton
                                    sx={{
                                        pl: 6, pr: 3,
                                        backgroundColor: decodeURIComponent(location.pathname) === `/result` ? 'white' : color,
                                        color: decodeURIComponent(location.pathname) === `/result` ? '' : 'white',
                                        '&:hover': {
                                            backgroundColor: '#white',
                                            color: 'black'
                                        },
                                    }}
                                    onClick={() => (pathHandler(`/result`))}>
                                    <ListItemText
                                        sx={{
                                            borderBottom: '1px solid' + color
                                        }} primary={'가맹점 정산'}/>
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </>
                }

                {memberInfo.role &&
                    <>
                        <ListItemButton
                            sx={{
                                pl: 4, marginRight: 0,
                                color: openShop ? 'white' : '',
                                backgroundColor: openShop ? color : '',
                                '&:hover': {
                                    backgroundColor: color,
                                    color: 'white'
                                },
                            }}
                            onClick={openShopHandler}
                        >
                            <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                          primary="제휴사 관리"/>
                            {openShop ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={openShop} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {shopMenuList.length !== 0 && shopMenuList.map((menu, idx) => (
                                    <ListItemButton key={idx}
                                                    sx={{
                                                        pl: 6, pr: 3,
                                                        backgroundColor: decodeURIComponent(location.pathname) === `/agency/${menu}` ? 'white' : color,
                                                        color: decodeURIComponent(location.pathname) === `/agency/${menu}` ? '' : 'white',
                                                        '&:hover': {
                                                            backgroundColor: '#white',
                                                            color: 'black'
                                                        },
                                                    }}
                                                    onClick={() => (pathHandler(`/agency/${menu}`))}>
                                        <ListItemText
                                            sx={{
                                                borderBottom: '1px solid' + color
                                            }} primary={menu}/>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                    </>
                }

                {memberInfo.role === 'ROLE_ADMIN' &&
                    <>
                        <ListItemButton onClick={openManageHandler}
                                        sx={{
                                            pl: 4, marginRight: 0,
                                            color: openManage ? 'white' : '',
                                            backgroundColor: openManage ? color : '',
                                            '&:hover': {
                                                backgroundColor: '#white',
                                                color: 'black'
                                            },
                                        }}
                        >
                            <ListItemText sx={{
                                borderBottom: '1px solid' + color

                            }} className={'pointer'}
                                          primary="관리자 메뉴"/>
                            {openManage ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={openManage} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>

                                {/*<ListItem onClick={() => pathHandler('/holdList')} button*/}
                                {/*          sx={{*/}
                                {/*              pl: 5, pr: 3,*/}
                                {/*              backgroundColor: location.pathname !== `/holdList` ? '#004E8A' : 'white',*/}
                                {/*              color: location.pathname !== `/holdList` ? 'white' : '',*/}
                                {/*              '&:hover': {*/}
                                {/*                  backgroundColor: '#white',*/}
                                {/*                  color: 'black'*/}
                                {/*              },*/}
                                {/*          }}*/}
                                {/*>*/}
                                {/*    <ListItemText sx={{borderBottom: '1px solid #004E8A'}} className={'statistics'}*/}
                                {/*                  primary="정산 보류"/>*/}
                                {/*</ListItem>*/}
                                <ListItem onClick={() => pathHandler('/permit')} button
                                          sx={{
                                              pl: 5, pr: 3,
                                              backgroundColor: location.pathname !== `/permit` ? color : 'white',
                                              color: location.pathname !== `/permit` ? 'white' : '',
                                              '&:hover': {
                                                  backgroundColor: '#white',
                                                  color: 'black'
                                              },
                                          }}
                                >
                                    <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                  primary="가입 승인"/>
                                </ListItem>
                                <ListItem onClick={() => pathHandler('/pg')} button
                                          sx={{
                                              pl: 5, pr: 3,
                                              backgroundColor: location.pathname !== `/pg` ? color : 'white',
                                              color: location.pathname !== `/pg` ? 'white' : '',
                                              '&:hover': {
                                                  backgroundColor: '#white',
                                                  color: 'black'
                                              },
                                          }}>
                                    <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                  primary="PG사 등록"/>
                                </ListItem>
                                <ListItem onClick={() => pathHandler('/joinId')} button
                                          sx={{
                                              pl: 5, pr: 3,
                                              backgroundColor: location.pathname !== `/joinId` ? color : 'white',
                                              color: location.pathname !== `/joinId` ? 'white' : '',
                                              '&:hover': {
                                                  backgroundColor: '#white',
                                                  color: 'black'
                                              },
                                          }}>
                                    <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                  primary="관리자 아이디"/>
                                </ListItem>
                                <ListItem onClick={() => pathHandler('/chargeErr')} button
                                          sx={{
                                              pl: 5, pr: 3,
                                              backgroundColor: location.pathname !== `/chargeErr` ? color : 'white',
                                              color: location.pathname !== `/chargeErr` ? 'white' : '',
                                              '&:hover': {
                                                  backgroundColor: '#white',
                                                  color: 'black'
                                              },
                                          }}>
                                    <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                  primary="비 정상 수수료"/>
                                </ListItem>
                                <ListItem onClick={() => pathHandler('/payCheck')} button
                                          sx={{
                                              pl: 5, pr: 3,
                                              backgroundColor: location.pathname !== `/payCheck` ? color : 'white',
                                              color: location.pathname !== `/payCheck` ? 'white' : '',
                                              '&:hover': {
                                                  backgroundColor: '#white',
                                                  color: 'black'
                                              },
                                          }}>
                                    <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                  primary="매출 체크"/>
                                </ListItem>
                                <ListItem onClick={() => pathHandler('/terminalList')} button
                                          sx={{
                                              pl: 5, pr: 3,
                                              backgroundColor: location.pathname !== `/terminalList` ? color : 'white',
                                              color: location.pathname !== `/terminalList` ? 'white' : '',
                                              '&:hover': {
                                                  backgroundColor: '#white',
                                                  color: 'black'
                                              },
                                          }}>
                                    <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                  primary="터미널 목록"/>
                                </ListItem>
                                <ListItem onClick={() => pathHandler('/notiDrop')} button
                                          sx={{
                                              pl: 5, pr: 3,
                                              backgroundColor: location.pathname !== `/terminalList` ? color : 'white',
                                              color: location.pathname !== `/terminalList` ? 'white' : '',
                                              '&:hover': {
                                                  backgroundColor: '#white',
                                                  color: 'black'
                                              },
                                          }}>
                                    <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                  primary="노티 누락 목록"/>
                                </ListItem>
                                {['ggpay.kr', 'office.ggpay.kr'].includes(window.location.hostname) && (
                                    <ListItem onClick={() => pathHandler('/contact')} button
                                              sx={{
                                                  pl: 5, pr: 3,
                                                  backgroundColor: location.pathname !== `/contact` ? color : 'white',
                                                  color: location.pathname !== `/contact` ? 'white' : '',
                                                  '&:hover': {
                                                      backgroundColor: '#white',
                                                      color: 'black'
                                                  },
                                              }}>
                                        <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                      primary="문의접수 목록"/>
                                    </ListItem>
                                )}
                            </List>
                        </Collapse>
                    </>

                }

                {memberInfo.memRole !== '가맹점' &&
                    <>
                        <ListItemButton onClick={openJoin}
                                        sx={{
                                            pl: 4,
                                            color: join ? 'white' : '',
                                            backgroundColor: join ? color : '',
                                            '&:hover': {
                                                backgroundColor: color,
                                                color: 'white'
                                            },
                                        }}
                        >
                            <ListItemText sx={{borderBottom: '1px solid' + color, marginRight: 1}} className={'pointer'}
                                          primary="회원가입"/>
                            {join ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={join} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {memberInfo.roleRank < 2 &&
                                    <ListItem onClick={() => pathHandler('/join/agency')} button
                                              sx={{
                                                  pl: 6, pr: 5,
                                                  backgroundColor: location.pathname !== `/join/agency` ? color : 'white',
                                                  color: location.pathname !== `/join/agency` ? 'white' : '',
                                                  '&:hover': {
                                                      backgroundColor: '#white',
                                                      color: 'black'
                                                  },
                                              }}
                                    >
                                        <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                      primary="대행사"/>
                                    </ListItem>
                                }
                                {memberInfo.roleRank < 3 &&
                                    <ListItem onClick={() => pathHandler('/join/branch')} button
                                              sx={{
                                                  pl: 6, pr: 5,
                                                  backgroundColor: location.pathname !== `/join/branch` ? color : 'white',
                                                  color: location.pathname !== `/join/branch` ? 'white' : '',
                                                  '&:hover': {
                                                      backgroundColor: '#white',
                                                      color: 'black'
                                                  },
                                              }}>
                                        <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                      primary="지사"/>
                                    </ListItem>
                                }
                                {memberInfo.roleRank < 4 &&
                                    <ListItem onClick={() => pathHandler('/join/distributor')} button

                                              sx={{
                                                  pl: 6, pr: 5,
                                                  backgroundColor: location.pathname !== `/join/distributor` ? color : 'white',
                                                  color: location.pathname !== `/join/distributor` ? 'white' : '',
                                                  '&:hover': {
                                                      backgroundColor: '#white',
                                                      color: 'black'
                                                  },
                                              }}>
                                        <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                      primary="총판"/>
                                    </ListItem>
                                }
                                {memberInfo.roleRank < 5 &&
                                    <ListItem onClick={() => pathHandler('/join/dealer')} button
                                              sx={{
                                                  pl: 6, pr: 5,
                                                  backgroundColor: location.pathname !== `/join/dealer` ? color : 'white',
                                                  color: location.pathname !== `/join/dealer` ? 'white' : '',
                                                  '&:hover': {
                                                      backgroundColor: '#white',
                                                      color: 'black'
                                                  },
                                              }}>
                                        <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                      primary="대리점"/>
                                    </ListItem>
                                }
                                {memberInfo.roleRank < 6 &&
                                    <ListItem onClick={() => pathHandler('/join/franchise')} button
                                              sx={{
                                                  pl: 6, pr: 5,
                                                  backgroundColor: location.pathname !== `/join/franchise` ? color : 'white',
                                                  color: location.pathname !== `/join/franchise` ? 'white' : '',
                                                  '&:hover': {
                                                      backgroundColor: '#white',
                                                      color: 'black'
                                                  },
                                              }}>
                                        <ListItemText sx={{borderBottom: '1px solid' + color}} className={'pointer'}
                                                      primary="가맹점"/>
                                    </ListItem>
                                }
                            </List>
                        </Collapse>
                        <Collapse in={openDropdown} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button sx={{pl: 4}}>
                                    <ListItemText primary="Menu 1"/>
                                </ListItem>
                            </List>
                        </Collapse>
                    </>
                }
            </List>
        </Drawer>
    )
        ;
};

export default Menu;
