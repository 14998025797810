import React, {useEffect, useRef, useState} from 'react';
import {
    Grid,
    Pagination,
    PaginationItem,
    Paper, Switch,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {titleState} from "../../store/titleStore";
import {loadingState} from "../../store/loadingStore";
import axios from "../../axiosInstance";
import {useLocation} from "react-router-dom";
import {alarmState} from "../../store/alarmStore";

const ContactList = () => {

    const [page, setPage] = useState(1);
    const setTitle = useSetRecoilState(titleState);
    const [data, setData] = useState();
    const setLoadingState = useSetRecoilState(loadingState);
    const location = useLocation();
    const [isHome, setIsHome] = useState(location.pathname === '/');
    // ✅ AudioContext를 위한 Ref
    const alarmMode = useRecoilValue(alarmState);
    const audioRef = useRef(null);

    useEffect(() => {
        getData();
        if (!isHome) {
            setTitle("문의 내역");
        }

        // ✅ Audio 객체 초기화 (MP3 파일 로드)
        if (!audioRef.current) {
            audioRef.current = new Audio('/files/playsound.mp3');
        }

        // ✅ WebSocket 연결
        const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
        const socketUrl = `${protocol}office.ggpay.kr/ws/contact`;
        // const socketUrl = `ws://localhost:8080/ws/contact`;
        // const socketUrl = `wss://office.ggpay.kr/ws/contact`;

        const socket = new WebSocket(socketUrl);

        socket.onopen = () => {
            console.log("✅ WebSocket 연결 성공:", socketUrl);
        };

        socket.onmessage = (event) => {
            console.log("📩 WebSocket 메시지 수신:", event.data);
            if (alarmMode === 'on') {
                playSound();
            }
        };

        socket.onerror = (error) => {
            console.error("❌ WebSocket 오류 발생:", error);
        };

        socket.onclose = (event) => {
            console.warn("⚠️ WebSocket 연결 종료됨:", event.code, event.reason);
        };

        return () => {
            socket.close();
            console.log("🛑 WebSocket 연결 해제");
        };
    }, [alarmMode]);

    const playSound = () => {
        if (audioRef.current) {
            audioRef.current.play().catch(error => {
                console.error("자동 재생 실패:", error);
            });
        }
    };

    const handleSwitchChange = (contactId, isChecked) => {
        const payload = isChecked ? 'Y' : 'N';
        axios.patch(`/api/admin/contact/${contactId}?isCheck=${payload}`).then((response) => {
            // setSearchToken(Date.now());
        })
    };

    const getData = () => {
        axios.get(`/api/admin/contact?page=${page}`).then((res) => {
            setData(res.data);
            setLoadingState(false);
        });
    };

    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };


    return (
        <>

            <Grid container spacing={1} width={'80wh'}>

                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ overflowX: 'hidden' }}>
                        <Table size={'small'} sx={{minWidth: isHome ? 0 : 1300}}>
                            <TableHead sx={{backgroundColor: 'grey.200'}}>
                                <TableRow>
                                    {!isHome &&
                                        <TableCell align="center" component="th">
                                            접수번호
                                        </TableCell>
                                    }
                                    <TableCell align="center" component="th">
                                        담당자
                                    </TableCell>
                                    {!isHome &&
                                        <TableCell align="center" component="th">
                                            회사명
                                        </TableCell>
                                    }
                                    <TableCell align="center" component="th">
                                        연락처
                                    </TableCell>
                                    {!isHome &&
                                        <TableCell align="center" component="th">
                                            이메일
                                        </TableCell>
                                    }
                                    <TableCell align="center" component="th">
                                        제목
                                    </TableCell>
                                    {!isHome &&
                                        <>
                                            <TableCell align="center" component="th">
                                                내용
                                            </TableCell>
                                            <TableCell align="center" component="th">
                                                처리완료
                                            </TableCell>
                                        </>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.content?.map((row, idx) => (
                                    <TableRow key={idx}>
                                        {!isHome &&
                                            <TableCell width={'5%'} align="center">
                                                {row.contactId}
                                            </TableCell>
                                        }
                                        <TableCell width={'10%'} align="center">
                                            {row.name}
                                        </TableCell>
                                        {!isHome &&
                                            <TableCell width={'10%'} align="center">
                                                {row.compony}
                                            </TableCell>
                                        }
                                        <TableCell width={'10%'} align="center">
                                            {row.phone}
                                        </TableCell>
                                        {!isHome &&
                                            <TableCell width={'10%'} align="center">
                                                {row.email}
                                            </TableCell>
                                        }
                                        <TableCell width={isHome ? '50%' : '10%' } align="center">
                                            {row.title}
                                        </TableCell>
                                        {!isHome &&
                                            <>
                                                <TableCell align="center">
                                                    {row.request}
                                                </TableCell>
                                                <TableCell width={'5%'} align="center">
                                                    <Switch
                                                        defaultChecked={row?.isCheck === 'Y'}
                                                        onChange={(e) => handleSwitchChange(row.contactId, e.target.checked)}
                                                        size="small"
                                                    />
                                                </TableCell>
                                            </>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {!isHome &&
                    <Grid item xs={12}>
                        <Pagination
                            count={data?.pageSize > 0 ? Math.ceil(data?.total / data?.pageSize) : 0}
                            page={data?.currentPage || 1} // data.page가 undefined일 경우 기본값 1 사용
                            onChange={onPageChange}
                            size="medium"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "15px 0",
                            }}
                            renderItem={(item, idx) => (
                                <PaginationItem key={idx} {...item} sx={{fontSize: 12}}/>
                            )}
                        />
                    </Grid>
                }
            </Grid>
        </>
    );
};

export default ContactList;
