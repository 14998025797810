import React, {useEffect, useState} from "react";
import {useNavigate, Outlet, useLocation} from "react-router-dom";
import {Backdrop, Box, CircularProgress} from "@mui/material";
import Header from "./components/Header";
import Menu from "./components/Menu";
import axios from '../../axiosInstance';
import { useRecoilValue, useSetRecoilState} from "recoil";
import {memberState} from "../../store/memberStore";
import {cateList} from "../../store/cateStore";
import SidebarTrigger from "./components/SidebarTrigger";
import {loadingState} from "../../store/loadingStore";

const DashboardLayout = () => {
    const memberInfo = useRecoilValue(memberState);
    const [openSidebar, setOpenSidebar] = useState(true);
    const [openDropdown, setOpenDropdown] = useState(false);
    const navigate = useNavigate();
    const setShopMenuList = useSetRecoilState(cateList);
    const location = useLocation();
    const loading = useRecoilValue(loadingState);

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpenSidebar(false);
        }, 100);

        return () => clearTimeout(timer);
    }, [location]);

    useEffect(() => {
        getShopMenuList()
    }, [memberInfo]);

    const getShopMenuList = () => {
        axios.get(`/api/member/shopMenu/${memberInfo.memRole}`).then(response => {
            setShopMenuList(response.data);
        }).catch()
    }

    const toggleSidebar = () => {
        setOpenSidebar(!openSidebar);
    };

    const handleClick = () => {
        setOpenDropdown(!openDropdown);
    };

    const pathHandler = (path) => {
        navigate(path);
    };


    const Offset = () => {
        return <Box sx={{ minHeight: 64 }} />;
    };
    return (
        <Box sx={{ flexGrow: 1, bgcolor: "white" }}>
            <SidebarTrigger setOpenSidebar={setOpenSidebar} />
            <Header toggleSidebar={toggleSidebar} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Offset />
            <Menu
                openSidebar={openSidebar}
                openDropdown={openDropdown}
                toggleSidebar={toggleSidebar}
                handleClick={handleClick}
                pathHandler={pathHandler}
            />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    paddingLeft: openSidebar ? "200px" : '30px',

                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
};

export default DashboardLayout;
