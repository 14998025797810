import React, {useEffect, useState} from 'react'
import {Grid, Typography, Paper, Divider, IconButton} from "@mui/material";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberState} from "../../store/memberStore";
import axios from '../../axiosInstance';
import {
    BarChart,
    Bar,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import {titleState} from "../../store/titleStore";
import {pageSetState} from "../../store/pageSetStore";
import Bbs from "../bbs/Bbs";
import PayPrice from "./components/PayPrice";
import PayCheck from "../payCheck/PayCheck";
import PermitHome from "./components/PermitHome";
import ShopResult from "./components/ShopResult";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from "react-router-dom";
import {colorState} from "../../store/colorStore";
import NotiDrop from "../notiDrop/NotiDrop";
import ContactList from "../contact/ContactList";

export default function Home() {

    const pageSet = useRecoilValue(pageSetState);
    const [data, setData] = useState([]);
    const memberInfo = useRecoilValue(memberState);
    const [thisMonthData, setThisMonthData] = useState({date: [], amt: [], fullDate: []});
    const [thisYearData, setThisYearData] = useState({date: [], amt: [], fullDate: []});
    const setTitle = useSetRecoilState(titleState);
    const navigate = useNavigate();
    const color = useRecoilValue(colorState);


    useEffect(() => {
        getData();
        setTitle(pageSet);
    }, []);

    const handleAddClick = (payload) => {
        navigate(payload);
    };

    const getData = () => {
        axios.get(`/api/pay/home/${memberInfo.memCode}`).then((response) => {
            setData(response.data)
            // console.log(response.data.thisYear)
            setMonthChartData(response.data)
            setYearChartData(response.data.thisYear)
        }).catch()
    }

    const setMonthChartData = (data) => {
        const formattedData = data.thisMonth.map(item => ({
            date: parseInt(item.PAY_DATE.substring(6, 8), 10), // X축에 표시될 일자
            fullDate: `${item.PAY_DATE.substring(0, 4)}/${item.PAY_DATE.substring(4, 6)}/${item.PAY_DATE.substring(6, 8)}`, // 마우스 오버시 표시될 날짜 형식
            amt: parseInt(item.ORIGIN_AMT, 10) // 숫자 유형으로 유지
        }));
        setThisMonthData(formattedData);
    };

    const setYearChartData = (data) => {
        const formattedData = data.map(item => ({
            date: item.PAY_DATE.substring(2, 4) + '/' + item.PAY_DATE.substring(4, 6),
            fullDate: `${item.PAY_DATE.substring(0, 4)}/${item.PAY_DATE.substring(4, 6)}`, // 'YYYY/MM' 형식으로 변환
            amt: parseInt(item.ORIGIN_AMT, 10), // 숫자로 변환하여 천 단위 구분 기호 적용은 툴팁에서 처리
        }));
        setThisYearData(formattedData);
    };
    const titleStyle = {color: color, marginBottom: '4px'};
    const paperStyle = {
        borderRadius: 2,
        p: 1.5,
        width: '100%',
        border: '1px solid #ccc',
        minHeight: '250px',
        maxHeight: '250px'
    };
    const contentStyle = {overflowY: 'auto', minHeight: '195px', maxHeight: '195px'}; // 추가된 스타일


    return (
        <>
            <Grid container spacing={3}>
                <Grid item container xs={12} md={6} spacing={1}>
                    <Grid item xs={12} md={memberInfo.memRole === '본사' ? 6 : 12}>
                        <Paper elevation={4} sx={{borderRadius: 2, p: 2, ...paperStyle}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography variant="h6" align="left" sx={titleStyle}>공지사항</Typography>
                                <IconButton onClick={() => (handleAddClick('/bbs'))} sx={{color: color}}>
                                    <AddIcon/>
                                </IconButton>
                            </div>
                            <Divider sx={{borderColor: color, borderBottomWidth: '2px'}}/>
                            <div style={contentStyle}>
                                <Bbs/>
                            </div>
                        </Paper>
                    </Grid>
                    {memberInfo.memRole === '본사' &&
                        <Grid item xs={12} md={6}>
                            <Paper elevation={4} sx={{borderRadius: 2, p: 2, ...paperStyle}}>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Typography variant="h6" align="left" sx={titleStyle}>가입 승인 요청</Typography>
                                </div>
                                <Divider sx={{borderColor: color, borderBottomWidth: '2px'}}/>
                                <div style={contentStyle}>
                                    <PermitHome></PermitHome>
                                </div>
                            </Paper>
                        </Grid>
                    }
                </Grid>
                {memberInfo.memRole === '본사' &&
                    <>
                        {['ggpay.kr', 'office.ggpay.kr'].includes(window.location.hostname) && (
                            <Grid item xs={12} md={6}>
                                <Paper elevation={4} sx={paperStyle}>

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <Typography variant="h6" align="left" sx={titleStyle}>문의 접수 목록</Typography>
                                        <IconButton onClick={() => (handleAddClick('/contact'))} sx={{color: color}}>
                                            <AddIcon/>
                                        </IconButton>
                                    </div>
                                    <Divider sx={{borderColor: color, borderBottomWidth: '2px'}}/>
                                    <div style={contentStyle}>
                                        <ContactList/>
                                    </div>


                                </Paper>
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                            <Paper elevation={4} sx={paperStyle}>
                                <Typography variant="h6" align="left" sx={titleStyle}>노티 누락 목록</Typography>
                                <Divider sx={{borderColor: color, borderBottomWidth: '3px'}}/>
                                {/* 내용 부분 */}
                                <div style={contentStyle}>
                                    <NotiDrop></NotiDrop>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={4} sx={paperStyle}>
                                <Typography variant="h6" align="left" sx={titleStyle}>연매출 초과 가맹점</Typography>
                                <Divider sx={{borderColor: color, borderBottomWidth: '3px'}}/>
                                <div style={contentStyle}>
                                    <PayCheck/>
                                </div>
                                {/* 내용 부분 */}
                            </Paper>
                        </Grid>
                    </>
                }

                <Grid item xs={12} md={6}>
                    <Paper elevation={4} sx={paperStyle}>
                        <Typography variant="h6" align="left" sx={titleStyle}>결제 금액</Typography>
                        <Divider sx={{borderColor: color, borderBottomWidth: '3px'}}/>
                        {/* 내용 부분 */}
                        <PayPrice data={data}></PayPrice>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper elevation={4} sx={paperStyle}>
                        <Typography variant="h6" align="left" sx={titleStyle}>지사별 월 결제금액</Typography>
                        <Divider sx={{borderColor: color, borderBottomWidth: '3px'}}/>
                        {/* 내용 부분 */}
                        <div style={contentStyle}>

                            <ShopResult></ShopResult>
                        </div>
                    </Paper>
                </Grid>
                <Grid item container xs={12} md={6} spacing={1} sx={{height: '380px'}}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={4} sx={{borderRadius: 2, p: 2, width: '100%'}}>
                            <Typography variant="h6" align="center" gutterBottom>일별 승인금액</Typography>
                            {thisMonthData &&
                                <ResponsiveContainer width="100%" height={180}>
                                    <LineChart data={thisMonthData}
                                               margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="fullDate"
                                               tickFormatter={(fullDate) => fullDate.substring(5)}/>
                                        <YAxis tick={{fontSize: '12px'}}
                                               tickFormatter={(value) => value.toLocaleString()}/>
                                        <Tooltip formatter={(value, name) => [value.toLocaleString(), "승인금액"]}/>
                                        <Line type="monotone" dataKey="amt" stroke="#8884d8" strokeWidth={4}/>
                                    </LineChart>
                                </ResponsiveContainer>
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={4} sx={{borderRadius: 2, p: 2, width: '100%'}}>
                            <Typography variant="h6" align="center" gutterBottom>월별 승인금액</Typography>
                            {thisYearData &&
                                <ResponsiveContainer width="100%" height={180}>
                                    <BarChart data={thisYearData}>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="date"/>
                                        <YAxis tick={{fontSize: '12px'}}
                                               tickFormatter={(value) => value.toLocaleString()}/>
                                        <Tooltip formatter={(value, name) => [`${value.toLocaleString()}`, "승인금액"]}
                                                 labelFormatter={(label) => thisYearData.find(d => d.date === label)?.fullDate ?? ''}/>
                                        <Bar dataKey="amt" fill="#8884d8" barSize={30}/>
                                    </BarChart>
                                </ResponsiveContainer>
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}
